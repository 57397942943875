<template>
  <div>
    <div v-show="mostrar_blocos" class="grid">
      <div class="col-12 lg:col-6 xl:col-4">
        <div class="card mb-0 bg-warning">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-900 text-xl font-medium mb-3">Cockpit Implantação</span>
              <div class="text-900 font-medium text-xl"><span style="font-size: 20px;">{{ cockpit }}</span> <small class="text-500 font-medium">Fatura(s)</small></div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
                bg-orange-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-info-circle text-orange-500 text-xl"></i>
            </div>
          </div>
          <router-link to="/faturas/cockpit" class="text-orange-500 font-medium">Corrigir Faturas</router-link>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-4">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Faturas Implantadas</span>
              <div class="text-900 font-medium text-xl">{{ faturas_implantadas }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-orange-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-cloud-upload text-blue-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium">Veja Mais</span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-4">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Faturas Implantadas no Mês</span>
              <div class="text-900 font-medium text-xl">{{ faturas_implantadas_mes  }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-cyan-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-check-circle text-cyan-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium">Veja Mais </span>
        </div>
      </div>
        <!-- <div class="col-12 lg:col-6 xl:col-3">
          <div class="card mb-0">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">Faturas Esperada no Mês</span>
                <div class="text-900 font-medium text-xl">20.322</div>
              </div>
              <div class="
                flex
                align-items-center
                justify-content-center
                bg-red-100
                border-round
              " style="width: 2.5rem; height: 2.5rem">
                <i class="pi pi-exclamation-triangle text-red-500 text-xl"></i>
              </div>
            </div>
            <span class="text-green-500 font-medium">Veja Mais</span>
          </div>
        </div> -->
    </div>

    <div class="col-12 xl:col-12" v-show="mostrar_blocos">
      <div class="card">

        <DataTable ref="dt" :value="faturas" v-model:expandedRows="selectImplantacoes" dataKey="id" :paginator="true"
          :rows="20" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Últimas Implantações</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column select style="width: 5rem" />

          <Column field="id" header="ID" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="id_extracao" header="Extração" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Extração</span>
              {{ slotProps.data.id_extracao }}
            </template>
          </Column>
          <Column field="razao_social" header="Empresa" :sortable="true" headerStyle="width:45%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Empresa</span>
              {{ slotProps.data.razao_social }}
            </template>
          </Column>
          <Column field="unidade" header="Unidade" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Unidade</span>
              {{ slotProps.data.unidade }}
            </template>
          </Column>

          <Column field="mes_referencia" header="Mes Referencia" :sortable="true"
            headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Mes Referencia</span>
              {{ slotProps.data.mes_referencia }}
            </template>
          </Column>

          <Column field="descricao_insumo" header="Insumo" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Insumo</span>
              {{ slotProps.data.descricao_insumo }}
            </template>
          </Column>

          <Column field="descricao_concessionaria" header="Concessionaria" :sortable="true"
            headerStyle="width:14%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Concessionaria</span>
              {{ slotProps.data.descricao_concessionaria }}
            </template>
          </Column>




          <Column field="status" header="Status" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              {{ slotProps.data.status }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>

</template>

<script>
import EventBus from "@/AppEventBus";

import { FilterMatchMode } from 'primevue/api';
export default {
  data() {
    return {
      planilha_fornecedor: [],
      status: [
        { label: "Aguardando análise", value: 0 },
        { label: "Aprovado", value: 1 },
        { label: "Reprovado", value: 2 },


      ],

      cabecalho: [
        { header: "Site ID TIM", field: 'site_id_tim' },
        { header: "END ID TIM", field: 'endereco_id_tim' },
        { header: "Regional TIM", field: 'regional_tim' },
        { header: "UF", field: 'uf' },
        { header: "Detentora ", field: 'detentora' },
        { header: "Site ID Detentora", field: 'site_id_detentora' },
        { header: "Data RFI", field: 'data_rfi' },
        { header: "Tipo de Energia", field: 'tipo_energia' },
        { header: "Consumo (KWh)", field: 'consumo' },
        { header: "É viável Instalação de Medidor TIM?", field: 'viavel_instalacao_medidor_tim' },
        { header: "Justificativa", field: 'justificativa' },
        { header: "Mês de Referência do Formulário", field: 'mes_referencia_formulario' },
        { header: "Data de envio do formulário", field: 'data_envio_formulario' },
        { header: "Mês de Referência da Fatura", field: 'mes_referencia_fatura' },
        { header: "Valor Total Fatura (R$) ", field: 'valor_total_fatura' },
        { header: "Concatenado (END_ID + Dententora)", field: 'concatenado' },

      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      planilhas: [],
      faturas_implantadas: 0,
      faturas_implantadas_mes: 0,
      mostrarPlanilhaModal: false,
      unidades: 0,
      cockpit:0,
      faturas: 0,
      id_status: 0,
      id_planilha: 0,
      planilhas_importadas: 0,
      planilhas_validar: 0,
      planilhas_validadas: 0,
      mostrar_blocos: true,
      mostrar_blocos_fornecedor: false,
      lineData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Revenue",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
          },
          {
            label: "Sales",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
          },
        ],
      },
      items: [
        { label: "Add New", icon: "pi pi-fw pi-plus" },
        { label: "Remove", icon: "pi pi-fw pi-minus" },
      ],
      lineOptions: null,
    };
  },
  productService: null,
  themeChangeListener: null,
  mounted() {
    this.montarHome();
    this.listarInicial();
    this.listarFaturas();

    this.themeChangeListener = (event) => {
      if (event.dark) this.applyDarkTheme();
      else this.applyLightTheme();
    };
    EventBus.on("change-theme", this.themeChangeListener);

    if (this.isDarkTheme()) {
      this.applyDarkTheme();
    } else {
      this.applyLightTheme();
    }
  },
  beforeUnmount() {
    EventBus.off("change-theme", this.themeChangeListener);
  },
  created() {

  },
  methods: {
    listarFaturas() {
      this.axios
        .get("/faturas/listar")
        .then((response) => {
          this.faturas = response.data.dados;
          console.log(this.faturas)
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },

    salvarStatus() {
      let dados = {
        id: this.id_planilha,
        id_status: this.id_status
      }
      this.axios
        .post("/fornecedores/salvarstatus", dados)
        .then(() => {
          this.mostrarPlanilhaModal = false;
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Status atualizado com sucesso.",
            life: 3000,
          });
          this.listarInicial();
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    listarRelatorio(id) {
      let dados = {
        id: id
      }
      this.axios
        .post("/relatorios/fornecedor", dados)
        .then((response) => {
          console.log(response);
          this.planilha_fornecedor = response.data.dados;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    mostrarPlanilha(planilha) {
      this.planilha = planilha;
      this.listarRelatorio(planilha.id);
      this.id_status = planilha.id_status
      this.id_planilha = planilha.id;
      this.mostrarPlanilhaModal = true;
    },
    montarHome() {
      let user = JSON.parse(localStorage.getItem("user"));

      if (user.id_tipo == 22913699) {
        this.mostrar_blocos = false;
        this.mostrar_blocos_fornecedor = true;
      }
    },
    listarInicial() {
      this.axios
        .post("home/inicial")
        .then((response) => {
          console.log(response)
          this.unidades = response.data.dados.unidades
          this.faturas_implantadas = response.data.dados.faturas
          this.cockpit = response.data.dados.cockpit;
          this.faturas_implantadas_mes = response.data.dados.implantacoes_mes;

        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    isDarkTheme() {
      return this.$appState.darkTheme === true;
    },
    applyLightTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      };
    },
    applyDarkTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: "#ebedef",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#ebedef",
            },
            grid: {
              color: "rgba(160, 167, 181, .3)",
            },
          },
          y: {
            ticks: {
              color: "#ebedef",
            },
            grid: {
              color: "rgba(160, 167, 181, .3)",
            },
          },
        },
      };
    },
  },
};
</script>